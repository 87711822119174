import { useEffect, useState } from 'react';

function TeamIndex(props) {
  const {
    teamIndexList,
    competition,
    step
  } = props

  const [competitionTeams, setCompetitionTeams] = useState([])

  useEffect(() => {
    var compTeams = teamIndexList.filter(x => x.competitionName === competition.label)[0]
    var sortedCompetitionTeams = compTeams.teams.sort((a, b) => {
      if (a.teamName < b.teamName) {
          return -1;
      }
      if (a.teamName > b.teamName) {
          return 1;
      }
      return 0;
    });
  
    setCompetitionTeams(sortedCompetitionTeams)
  },[])

  console.log(competitionTeams)

  return (
    <div className="App">
      <h1 id='team-index-header'>{competition.label} Teams</h1>
        {competitionTeams.map((team, i) => {
          return(
            <div className='competition-team-div' key={(team.teamName + i + competition.label)}>
              <h3>{team.teamName}</h3>
              {team.teamMembers.map((member,i) => {
                return(
                  <div className="teamMember-div" key={member + i + competition.Label}>
                    <p className='teamMember-p'>{member}</p>
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  );
}

export default TeamIndex;