import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';



export default function DisplayTeam(props) {
  const { title, groupMembers, removeTeamMember } = props

  return (
    <div>
      <div className='teamMember-box'>
        <h3>{title}s</h3>
        {groupMembers?.map((tm,i) => {
          return (
            <div id="team-member-list" key={tm?.label + i}>
              <div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', margin: '0 0 5px 0'}}>
                    <strong style={{width: '15px', marginRight: '15px'}}>{i + 1}.</strong>
                    <p style={{margin: '0px', textAlign: 'left'}}>{tm.label}</p>
                  </div>
                  <CloseIcon onClick={() => removeTeamMember(tm.label)}/>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

